<template>
	<div v-if="getGrafanaDisplay(tab).length > 0">
		<template v-for="(item,key) in getGrafanaDisplay(tab)" >
			<BaseCard :class="['chart', { hideCardHeader : item.hideHeaderTitle === true}]" :key="key">
				<template v-slot:header>
					<template v-if="item.hideHeaderTitle === false">
						<h4>
							<span class="title-iframe" :title="item.title">{{item.title}}</span>
						</h4>
						<div class="detail-card-header" >
						</div>
					</template>
				</template>
				<template v-slot:body>
					<PowerBI  v-if="item.type === GRAFANA_TYPE.POWER_BI" :data="{ data : item.powerBi}" :suffixContainerKey="item.uniqueId"/>
					<div v-else :class="['iframe-container', { borderless : item.borderless === true}]" >
						<div ref="iframe-information" class="iframe" v-html="item.url"></div>
					</div>
				</template>
			</BaseCard>
		</template>
	</div>
	<BaseNoData
		v-else-if="showBaseNoData"
		class="empty"
		description="BIG portal will start showing the grafana right after we have information"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import BaseCard from "@/components/BaseCard.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import PowerBI from "@/components/PowerBI.vue";
import { CONFIG, GRAFANA_TYPE } from "../../enums/config";

export default {
	name: "GrafanaDisplay",

	components: {
		BaseCard,
		BaseNoData,
		PowerBI
	},

	props: {
		deviceId: {
			type: [String, Number],
			default: null
		},
		tab: {
			type: String,
			default: "Overview"
		},
		showNoData: {
			type: Boolean,
			default: true
		}
	},

	mounted() {
		document.addEventListener("keydown", this.disableEscKey);
	},

	data() {
		return {
			templateId: this.$route.params.templateId ?? null,
			GRAFANA_TYPE
		};
	},

	async created() {
		if (this.deviceId !== null) {
			await this.getGrafanaDashboardByDeviceId(this.deviceId);
		}

		this.autoRefreshIframe = setInterval(() => {
			this.attachIframeListeners();
		}, CONFIG.AUTO_REFRESH_IFRAME);
	},

	beforeDestroy() {
		clearInterval(this.autoRefreshData);

		if (this.autoRefreshIframe) {
			clearInterval(this.autoRefreshIframe);
		}

		document.removeEventListener("keydown", this.disableEscKey);

		const iframeContainer = this.$refs["iframe-information"];
		if (iframeContainer) {
			iframeContainer.forEach((el) => {
				const iframe = el.querySelector("iframe");
				if (iframe) {
					iframe.contentWindow.document.removeEventListener("keydown", this.disableEscKey);
				}
			});
		}
	},

	computed: {
		...mapGetters("grafanaManagement", {
			getGrafanaDisplay: "getGrafanaDisplay"
		}),
		...mapState(["grafanaManagement"]),

		showBaseNoData() {
			return this.getGrafanaDisplay(this.tab).length === 0 && this.templateId && this.showNoData && this.grafanaManagement.isLoading === false;
		}
	},

	methods: {
		disableEscKey(event) {
			if (event.key === "Escape" || event.keyCode === 27) {
				event.preventDefault();
			}
		},

		...mapActions("grafanaManagement", {
			getGrafanaDashboardByDeviceId: "getGrafanaDashboardByDeviceId"
		}),

		attachIframeListeners() {
			const iframeContainer = this.$refs["iframe-information"];
			if (iframeContainer) {
				iframeContainer.forEach((el) => {
					const iframe = el.querySelector("iframe");
					if (iframe) {
						const token = iframe.getAttribute("token");
						if (token) {
							if (iframe.contentDocument?.readyState === "complete") {
								this.sendTokenMassage(iframe, token);
							} else {
								this.sendTokenMassage(iframe, token);
							}
						}
					}

					if (iframe) {
						iframe.contentWindow.document.addEventListener("keydown", this.disableEscKey);
					}
				});
			}
		},

		sendTokenMassage(iframe, token) {
			if (iframe.contentWindow) {
				iframe.contentWindow.postMessage(
					{ type: "jwt-token", content: token },
					"*"
				);
				clearInterval(this.autoRefreshIframe);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.iframe-container {
	padding: rem(24);

	&.borderless {
		padding: 0;

		.iframe {
			padding: 0;
			border: none;
		}
	}

	.iframe {
		padding: rem(24);
		border: 1px solid $color-grey-3;
	}
}

.detail-card-header {
	display: flex;
	align-items: center;

	& > * {
		margin-left: rem(24);
	}

	span {
		display: flex;
		color: $color-grey-4;
		cursor: pointer;
	}
}

.title-iframe {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.chart {
	&.hideCardHeader {
		::v-deep .card-header {
			padding: 0;
		}
	}

	h4 {
		width: 70%;
	}

	::v-deep .card-body {
		padding: 0;
	}
}

.empty {
	margin-top: rem(48);
}

.power-bi-container {
	margin-bottom: 0;
}
</style>
